<template>
  <div id="next-stop-settings">
    <div class="page-title px-4">
      <h2>
        <span class="me-2">Settings</span>
      </h2>
      <save-button :save-action="saveSettings" />
    </div>
    <v-divider class="mt-4 mb-10"></v-divider>
    <div>
      <v-tabs v-model="currentTab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.icon">
          <v-icon class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>
            {{ tab.title }}
          </span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab">
        <!-- TTS Segments -->
        <v-tab-item>
          <div v-if="is_loading" class="inline-loader">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
          <div v-else class="tts-standard-segments">
            <v-form>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h2 class="text-left text-xl font-weight-semibold label">Next stop introduction</h2>

                      <v-alert border="left" color="info" text dense class="mt-4 mb-0 pb-0">
                        <p class="text--primary font-weight-medium font-italic">
                          These settings won’t currently take effect on bus.
                        </p>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row v-for="(option, index) in nextStopOptions" :key="index">
                    <v-col cols="5">
                      <v-textarea
                        v-model="option.intro_label"
                        label="Next stop introduction label"
                        placeholder="eg. Next stop..."
                        hint=""
                        hide-details
                        rows="2"
                        outlined
                      ></v-textarea>
                    </v-col>
                    <v-col cols="5">
                      <v-row>
                        <v-col cols="11">
                          <v-textarea
                            v-model="option.intro_tts"
                            label="Next stop introduction TTS"
                            placeholder="eg. Next stop..."
                            hint=""
                            hide-details
                            rows="2"
                            outlined
                          >
                          </v-textarea>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn fab dark small color="primary" @click="TTSpreview(option.intro_tts)">
                            <v-icon x-large>
                              {{ icons.mdiPlayCircle }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="2" class="d-flex align-center">
                      <v-row>
                        <v-col cols="2">
                          <v-divider class="mx-2" vertical></v-divider>
                        </v-col>
                        <v-col cols="5" class="d-flex justify-space-around">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                elevation="2"
                                v-bind="attrs"
                                color="primary"
                                icon
                                small
                                outlined
                                :disabled="index === 0"
                                v-on="on"
                                @click="moveOptionUp(index)"
                                ><v-icon>
                                  {{ icons.mdiMenuUp }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Move up</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                elevation="2"
                                v-bind="attrs"
                                color="primary"
                                icon
                                small
                                outlined
                                :disabled="index === nextStopOptions.length - 1"
                                v-on="on"
                                @click="moveOptiondown(index)"
                                ><v-icon>
                                  {{ icons.mdiMenuDown }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Move down</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="5" class="d-flex justify-center">
                          <v-btn
                            v-if="index !== 0"
                            color="error"
                            elevation="1"
                            small
                            @click="deleteNextStopOption(index)"
                            >Delete</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="d-flex justify-center">
                      <v-btn large color="primary" @click="addNextStopOption"> Add "next stop" option </v-btn>
                    </v-col>
                  </v-row>
                  <tooltip-for-text-to-speech-field></tooltip-for-text-to-speech-field>

                  <v-expansion-panels class="mt-4 mb-4">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <h3>Legacy introduction TTS field</h3>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="11">
                            <v-alert border="left" color="info" text dense class="mt-4 mb-4 pb-0">
                              <p class="text--primary font-weight-medium font-italic">
                                This field is currently in use but will be deprecated in the future.
                              </p>
                            </v-alert>

                            <v-textarea
                              v-model="localSettings.intro_tts"
                              label="Next stop introduction TTS (legacy)"
                              placeholder="eg. Next stop..."
                              hint=""
                              hide-details
                              rows="2"
                              outlined
                            >
                            </v-textarea>
                          </v-col>
                          <v-col cols="1" class="d-flex justify-center align-center">
                            <v-btn
                              class="mb-8"
                              fab
                              dark
                              small
                              color="primary"
                              @click="TTSpreview(localSettings.intro_tts)"
                            >
                              <v-icon x-large>
                                {{ icons.mdiPlayCircle }}
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
              <v-card class="mt-5">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h2 class="text-left text-xl font-weight-semibold label">Transfers introduction</h2>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="11">
                      <v-textarea
                        v-model="localSettings.transfer_tts"
                        label="Transfers introduction TTS"
                        placeholder="eg. Transfer routes..."
                        rows="2"
                        hide-details
                        outlined
                      ></v-textarea>
                      <tooltip-for-text-to-speech-field></tooltip-for-text-to-speech-field>
                    </v-col>
                    <v-col cols="1" class="d-flex justify-center align-center">
                      <v-btn
                        class="mb-8"
                        fab
                        dark
                        small
                        color="primary"
                        @click="TTSpreview(localSettings.transfer_tts)"
                      >
                        <v-icon x-large>
                          {{ icons.mdiPlayCircle }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <h2 class="text-left text-xl font-weight-semibold label">Last stop message</h2>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="11">
                      <v-textarea
                        v-model="localSettings.last_stop_tts"
                        label="Last stop message TTS"
                        placeholder="eg. This is the last stop for this service"
                        rows="2"
                        hide-details
                        outlined
                      ></v-textarea>
                      <tooltip-for-text-to-speech-field></tooltip-for-text-to-speech-field>
                    </v-col>
                    <v-col cols="1" class="d-flex justify-center align-center">
                      <v-btn
                        class="mb-8"
                        fab
                        dark
                        small
                        color="primary"
                        @click="TTSpreview(localSettings.last_stop_tts)"
                      >
                        <v-icon x-large>
                          {{ icons.mdiPlayCircle }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <h2 class="text-left text-xl font-weight-semibold label">
                        Additional announcements introduction
                      </h2>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="11">
                      <v-textarea
                        v-model="localSettings.additional_intro_tts"
                        label="Additional announcements introduction TTS"
                        placeholder="eg. Stop here for..."
                        rows="2"
                        hide-details
                        outlined
                      ></v-textarea>
                      <tooltip-for-text-to-speech-field></tooltip-for-text-to-speech-field>
                    </v-col>
                    <v-col cols="1" class="d-flex justify-center align-center">
                      <v-btn
                        class="mb-8"
                        fab
                        dark
                        small
                        color="primary"
                        @click="TTSpreview(localSettings.additional_intro_tts)"
                      >
                        <v-icon x-large>
                          {{ icons.mdiPlayCircle }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>

            <audio :src="TTS" autoplay></audio>
          </div>
        </v-tab-item>

        <!-- Global Volume -->
        <v-tab-item>
          <div v-if="is_loading" class="inline-loader">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
          <v-card v-else>
            <v-card-text>
              <div class="global-volume">
                <h2 class="text-left text-xl font-weight-semibold label">Global volume</h2>

                <v-row class="pt-10 px-10">
                  <v-col cols="12" md="3"> Global off-peak volume </v-col>
                  <v-col cols="1">
                    <span class="font-weight-medium me-2">
                      <v-icon class="me-1" size="18" @click="localSettings.offpeak_volume = 0">
                        {{ localSettings.offpeak_volume == 0 ? icons.mdiVolumeOff : icons.mdiVolumeHigh }}
                      </v-icon>
                    </span>
                  </v-col>
                  <v-col cols="10" md="8">
                    <v-slider v-model="localSettings.offpeak_volume" color="primary" thumb-label="always"></v-slider>
                  </v-col>
                </v-row>
                <v-row class="px-10">
                  <v-col cols="12" md="3"> Global peak volume </v-col>
                  <v-col cols="1">
                    <span class="font-weight-medium me-2">
                      <v-icon class="me-1" size="18" @click="localSettings.peak_volume = 0">
                        {{ localSettings.peak_volume == 0 ? icons.mdiVolumeOff : icons.mdiVolumeHigh }}
                      </v-icon>
                    </span>
                  </v-col>
                  <v-col cols="10" md="8">
                    <v-slider v-model="localSettings.peak_volume" color="primary" thumb-label="always"></v-slider>
                  </v-col>
                </v-row>
                <v-row class="px-10">
                  <v-col cols="12" md="3"> Global quiet time volume </v-col>
                  <v-col cols="1">
                    <span class="font-weight-medium me-2">
                      <v-icon class="me-1" size="18" @click="localSettings.quiet_volume = 0">
                        {{ localSettings.quiet_volume == 0 ? icons.mdiVolumeOff : icons.mdiVolumeHigh }}
                      </v-icon>
                    </span>
                  </v-col>
                  <v-col cols="10" md="8">
                    <v-slider v-model="localSettings.quiet_volume" color="primary" thumb-label="always"></v-slider>
                  </v-col>
                </v-row>

                <!-- Weekday peak volume times-->
                <v-row>
                  <v-col>
                    <h2 class="text-left text-xl font-weight-semibold label">Weekday peak times</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-data-table
                      :headers="timePeriodHeaders"
                      :items="localSettings.weekday_peak"
                      :single-expand="false"
                      hide-default-footer
                      disable-pagination
                    >
                      <template v-slot:item.remove="{ item }">
                        <v-btn elevation="0" fab x-small>
                          <v-icon
                            class="d-flex justify-center align-center"
                            size="22"
                            color="primary"
                            @click="removeTimeSlot('weekday_peak', item)"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-10"></v-divider>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-card class="px-10 py-4" elevation="2">
                      <v-row>
                        <v-col> <h3>Add a new weekday peak time</h3> </v-col>
                      </v-row>
                      <v-row class="d-flex align-center">
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekdayPeakStart" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekdayPeak.start_time"
                                label="Start"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekdayPeakStart"
                              v-model="newWeekdayPeak.start_time"
                              color="primary"
                              full-width
                              @update:modelValue="newWeekdayPeakError = 'pending'"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekdayPeak.start_time = ''
                                  newWeekdayPeakStart = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekdayPeak')
                                  newWeekdayPeakStart = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="2"> To </v-col>
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekdayPeakEnd" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekdayPeak.end_time"
                                label="End"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekdayPeakEnd"
                              v-model="newWeekdayPeak.end_time"
                              color="primary"
                              full-width
                              @update:modelValue="newWeekdayPeakError = 'pending'"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekdayPeak.end_time = ''
                                  newWeekdayPeakEnd = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekdayPeak')
                                  newWeekdayPeakEnd = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="success"
                            :disabled="!newWeekdayPeak.start_time || !newWeekdayPeak.end_time || !!newWeekdayPeakError"
                            @click="saveNewTimeSlot('weekday_peak', 'newWeekdayPeak')"
                          >
                            <v-icon class="d-flex justify-center align-center" size="18">
                              {{ icons.mdiCheck }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="primary"
                            @click="newWeekdayPeak = { ...VolumePeriodModel }"
                          >
                            <v-icon class="d-flex justify-center align-center" size="28">
                              {{ icons.mdiCloseCircleOutline }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-col v-if="newWeekdayPeakError">
                        <span class="font-weight-semibold error--text">{{ newWeekdayPeakError }}</span>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- Weekend peak volume times-->
                <v-row>
                  <v-col>
                    <h2 class="text-left text-xl font-weight-semibold label">Weekend peak times</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-data-table
                      :headers="timePeriodHeaders"
                      :items="localSettings.weekend_peak"
                      :single-expand="false"
                      hide-default-footer
                      disable-pagination
                    >
                      <template v-slot:item.remove="{ item }">
                        <v-btn elevation="0" fab x-small>
                          <v-icon
                            class="d-flex justify-center align-center"
                            size="22"
                            color="primary"
                            @click="removeTimeSlot('weekend_peak', item)"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-10"></v-divider>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-card class="px-10 py-4" elevation="2">
                      <v-row>
                        <v-col> <h3>Add a new weekend peak time</h3> </v-col>
                      </v-row>
                      <v-row class="d-flex align-center">
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekendPeakStart" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekendPeak.start_time"
                                label="Start"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekendPeakStart"
                              v-model="newWeekendPeak.start_time"
                              color="primary"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekendPeak.start_time = ''
                                  newWeekendPeakStart = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekendPeak')
                                  newWeekendPeakStart = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="2"> To </v-col>
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekendPeakEnd" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekendPeak.end_time"
                                label="End"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekendPeakEnd"
                              v-model="newWeekendPeak.end_time"
                              color="primary"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekendPeak.end_time = ''
                                  newWeekendPeakEnd = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekendPeak')
                                  newWeekendPeakEnd = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="success"
                            :disabled="!newWeekendPeak.start_time || !newWeekendPeak.end_time || !!newWeekendPeakError"
                            @click="saveNewTimeSlot('weekend_peak', 'newWeekendPeak')"
                          >
                            <v-icon class="d-flex justify-center align-center" size="18">
                              {{ icons.mdiCheck }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="primary"
                            @click="newWeekendPeak = { ...VolumePeriodModel }"
                          >
                            <v-icon class="d-flex justify-center align-center" size="28">
                              {{ icons.mdiCloseCircleOutline }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-col v-if="newWeekendPeakError">
                        <span class="font-weight-semibold error--text">{{ newWeekendPeakError }}</span>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- Weekday quiet volume times-->
                <v-row>
                  <v-col>
                    <h2 class="text-left text-xl font-weight-semibold label">Weekday quiet times</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-data-table
                      :headers="timePeriodHeaders"
                      :items="localSettings.weekday_quiet"
                      :single-expand="false"
                      hide-default-footer
                      disable-pagination
                    >
                      <template v-slot:item.remove="{ item }">
                        <v-btn elevation="0" fab x-small>
                          <v-icon
                            class="d-flex justify-center align-center"
                            size="22"
                            color="primary"
                            @click="removeTimeSlot('weekday_quiet', item)"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-10"></v-divider>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-card class="px-10 py-4" elevation="2">
                      <v-row>
                        <v-col> <h3>Add a new weekday quiet time</h3> </v-col>
                      </v-row>
                      <v-row class="d-flex align-center">
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekdayQuietStart" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekdayQuiet.start_time"
                                label="Start"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekdayQuietStart"
                              v-model="newWeekdayQuiet.start_time"
                              color="primary"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekdayQuiet.start_time = ''
                                  newWeekdayQuietStart = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekdayQuiet')
                                  newWeekdayQuietStart = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="2"> To </v-col>
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekdayQuietEnd" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekdayQuiet.end_time"
                                label="End"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekdayQuietEnd"
                              v-model="newWeekdayQuiet.end_time"
                              color="primary"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekdayQuiet.end_time = ''
                                  newWeekdayQuietEnd = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekdayQuiet')
                                  newWeekdayQuietEnd = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="success"
                            :disabled="
                              !newWeekdayQuiet.start_time || !newWeekdayQuiet.end_time || !!newWeekdayQuietError
                            "
                            @click="saveNewTimeSlot('weekday_quiet', 'newWeekdayQuiet')"
                          >
                            <v-icon class="d-flex justify-center align-center" size="18">
                              {{ icons.mdiCheck }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="primary"
                            @click="newWeekdayQuiet = { ...VolumePeriodModel }"
                          >
                            <v-icon class="d-flex justify-center align-center" size="28">
                              {{ icons.mdiCloseCircleOutline }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-col v-if="newWeekdayQuietError">
                        <span class="font-weight-semibold error--text">{{ newWeekdayQuietError }}</span>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- Weekend quiet volume times-->
                <v-row>
                  <v-col>
                    <h2 class="text-left text-xl font-weight-semibold label">Weekend quiet times</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-data-table
                      :headers="timePeriodHeaders"
                      :items="localSettings.weekend_quiet"
                      :single-expand="false"
                      hide-default-footer
                      disable-pagination
                    >
                      <template v-slot:item.remove="{ item }">
                        <v-btn elevation="0" fab x-small>
                          <v-icon
                            class="d-flex justify-center align-center"
                            size="22"
                            color="primary"
                            @click="removeTimeSlot('weekend_quiet', item)"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-10"></v-divider>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-card class="px-10 py-4" elevation="2">
                      <v-row>
                        <v-col> <h3>Add a new weekend quiet time</h3> </v-col>
                      </v-row>
                      <v-row class="d-flex align-center">
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekendQuietStart" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekendQuiet.start_time"
                                label="Start"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekendQuietStart"
                              v-model="newWeekendQuiet.start_time"
                              color="primary"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekendQuiet.start_time = ''
                                  newWeekendQuietStart = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekendQuiet')
                                  newWeekendQuietStart = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="2"> To </v-col>
                        <v-col cols="4">
                          <v-dialog ref="dialog" v-model="newWeekendQuietEnd" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="newWeekendQuiet.end_time"
                                label="End"
                                :prepend-icon="icons.mdiClockTimeFourOutline"
                                readonly
                                outlined
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="newWeekendQuietEnd"
                              v-model="newWeekendQuiet.end_time"
                              color="primary"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  newWeekendQuiet.end_time = ''
                                  newWeekendQuietEnd = false
                                "
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  validateTimeRange('newWeekendQuiet')
                                  newWeekendQuietEnd = false
                                "
                              >
                                OK
                              </v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="success"
                            :disabled="
                              !newWeekendQuiet.start_time || !newWeekendQuiet.end_time || !!newWeekendQuietError
                            "
                            @click="saveNewTimeSlot('weekend_quiet', 'newWeekendQuiet')"
                          >
                            <v-icon class="d-flex justify-center align-center" size="18">
                              {{ icons.mdiCheck }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="1" class="d-flex justify-center align-center">
                          <v-btn
                            class="mb-8"
                            fab
                            dark
                            x-small
                            color="primary"
                            @click="newWeekendQuiet = { ...VolumePeriodModel }"
                          >
                            <v-icon class="d-flex justify-center align-center" size="28">
                              {{ icons.mdiCloseCircleOutline }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-col v-if="newWeekendQuietError">
                        <span class="font-weight-semibold error--text">{{ newWeekendQuietError }}</span>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>
                <!-- end of times -->
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- Announcment Settings -->
        <v-tab-item>
          <div v-if="is_loading" class="inline-loader">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
          <div v-else class="announcement-settings">
            <v-card class="mb-4">
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col>
                      <h2 class="text-left text-xl font-weight-semibold label">
                        Length to display the stop details during announcement
                      </h2>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-row class="d-flex align-center">
                        <v-col cols="6">
                          <h3 class="mb-3">Minimum (seconds)</h3>
                          <v-text-field
                            v-model="localSettings.min_display_duration"
                            label="Seconds"
                            outlined
                            dense
                            hint=""
                            hide-details
                            type="number"
                            min="0"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <h3 class="mb-3">Maximum (seconds)</h3>
                          <v-text-field
                            v-model="localSettings.max_display_duration"
                            label="Seconds"
                            outlined
                            dense
                            hint=""
                            hide-details
                            type="number"
                            min="0"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="8">
                          <div class="tool-tip-at-bottom">
                            <span>
                              These settings control how long the stop details page should stay open for, regardless of
                              the length of the announcement (which may be shorter due to no transfers or POI
                              announcements being made).
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>

            <v-card class="mb-4">
              <v-card-text>
                <!-- Weekday no transfer announcement times -->
                <v-row>
                  <v-col>
                    <h2 class="text-left text-xl font-weight-semibold label">
                      Disable Transfer Announcements - Weekdays
                    </h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-data-table
                      :headers="timePeriodHeaders"
                      :items="localSettings.weekday_no_transfer"
                      :single-expand="false"
                      hide-default-footer
                      disable-pagination
                    >
                      <template v-slot:item.remove="{ item }">
                        <v-btn elevation="0" fab x-small>
                          <v-icon
                            class="d-flex justify-center align-center"
                            size="22"
                            color="primary"
                            @click="removeTimeSlot('weekday_no_transfer', item)"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-10"></v-divider>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-row>
                      <v-col>
                        <h3>Add a weekday time period to disable transfer announcements</h3>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                      <v-col cols="4">
                        <v-dialog ref="dialog" v-model="newWeekdayNoTransferStart" persistent width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="newWeekdayNoTransfer.start_time"
                              label="Start"
                              :prepend-icon="icons.mdiClockTimeFourOutline"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="newWeekdayNoTransferStart"
                            v-model="newWeekdayNoTransfer.start_time"
                            color="primary"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                newWeekdayNoTransfer.start_time = ''
                                newWeekdayNoTransferStart = false
                              "
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                validateTimeRange('newWeekdayNoTransfer')
                                newWeekdayNoTransferStart = false
                              "
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="2"> To </v-col>
                      <v-col cols="4">
                        <v-dialog ref="dialog" v-model="newWeekdayNoTransferEnd" persistent width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="newWeekdayNoTransfer.end_time"
                              label="End"
                              :prepend-icon="icons.mdiClockTimeFourOutline"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="newWeekdayNoTransferEnd"
                            v-model="newWeekdayNoTransfer.end_time"
                            color="primary"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                newWeekdayNoTransfer.end_time = ''
                                newWeekdayNoTransferEnd = false
                              "
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                validateTimeRange('newWeekdayNoTransfer')
                                newWeekdayNoTransferEnd = false
                              "
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="1" class="d-flex justify-center align-center">
                        <v-btn
                          class="mb-8"
                          fab
                          dark
                          x-small
                          color="success"
                          :disabled="
                            !newWeekdayNoTransfer.start_time ||
                            !newWeekdayNoTransfer.end_time ||
                            !!newWeekdayNoTransferError
                          "
                          @click="saveNewTimeSlot('weekday_no_transfer', 'newWeekdayNoTransfer')"
                        >
                          <v-icon class="d-flex justify-center align-center" size="18">
                            {{ icons.mdiCheck }}
                          </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="1" class="d-flex justify-center align-center">
                        <v-btn
                          class="mb-8"
                          fab
                          dark
                          x-small
                          color="primary"
                          @click="newWeekdayNoTransfer = { ...VolumePeriodModel }"
                        >
                          <v-icon class="d-flex justify-center align-center" size="28">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-col v-if="newWeekdayNoTransferError">
                      <span class="font-weight-semibold error--text">{{ newWeekdayNoTransferError }}</span>
                    </v-col>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mb-4">
              <v-card-text>
                <!-- Weekend no transfer announcement times -->
                <v-row>
                  <v-col>
                    <h2 class="text-left text-xl font-weight-semibold label">
                      Disable Transfer Announcements - Weekends
                    </h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-data-table
                      :headers="timePeriodHeaders"
                      :items="localSettings.weekend_no_transfer"
                      :single-expand="false"
                      hide-default-footer
                      disable-pagination
                    >
                      <template v-slot:item.remove="{ item }">
                        <v-btn elevation="0" fab x-small>
                          <v-icon
                            class="d-flex justify-center align-center"
                            size="22"
                            color="primary"
                            @click="removeTimeSlot('weekend_no_transfer', item)"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-10"></v-divider>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-row>
                      <v-col>
                        <h3>Add a weekend time period to disable transfer announcements</h3>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                      <v-col cols="4">
                        <v-dialog ref="dialog" v-model="newWeekendNoTransferStart" persistent width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="newWeekendNoTransfer.start_time"
                              label="Start"
                              :prepend-icon="icons.mdiClockTimeFourOutline"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="newWeekendNoTransferStart"
                            v-model="newWeekendNoTransfer.start_time"
                            color="primary"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                newWeekendNoTransfer.start_time = ''
                                newWeekendNoTransferStart = false
                              "
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                validateTimeRange('newWeekendNoTransfer')
                                newWeekendNoTransferStart = false
                              "
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="2"> To </v-col>
                      <v-col cols="4">
                        <v-dialog ref="dialog" v-model="newWeekendNoTransferEnd" persistent width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="newWeekendNoTransfer.end_time"
                              label="End"
                              :prepend-icon="icons.mdiClockTimeFourOutline"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="newWeekendNoTransferEnd"
                            v-model="newWeekendNoTransfer.end_time"
                            color="primary"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                newWeekendNoTransfer.end_time = ''
                                newWeekendNoTransferEnd = false
                              "
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                validateTimeRange('newWeekendNoTransfer')
                                newWeekendNoTransferEnd = false
                              "
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="1" class="d-flex justify-center align-center">
                        <v-btn
                          class="mb-8"
                          fab
                          dark
                          x-small
                          color="success"
                          :disabled="
                            !newWeekendNoTransfer.start_time ||
                            !newWeekendNoTransfer.end_time ||
                            !!newWeekendNoTransferError
                          "
                          @click="saveNewTimeSlot('weekend_no_transfer', 'newWeekendNoTransfer')"
                        >
                          <v-icon class="d-flex justify-center align-center" size="18">
                            {{ icons.mdiCheck }}
                          </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="1" class="d-flex justify-center align-center">
                        <v-btn
                          class="mb-8"
                          fab
                          dark
                          x-small
                          color="primary"
                          @click="newWeekendNoTransfer = { ...VolumePeriodModel }"
                        >
                          <v-icon class="d-flex justify-center align-center" size="28">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-col v-if="newWeekendNoTransferError">
                      <span class="font-weight-semibold error--text">{{ newWeekendNoTransferError }}</span>
                    </v-col>
                  </v-col>
                </v-row>
                <!-- end of times -->
              </v-card-text>
            </v-card>

            <v-card class="mb-4">
              <v-card-text>
                <!-- Weekday no POI announcement times -->
                <v-row>
                  <v-col>
                    <h2 class="text-left text-xl font-weight-semibold label">
                      Disable Point of Interest Announcements - Weekdays
                    </h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-data-table
                      :headers="timePeriodHeaders"
                      :items="localSettings.weekday_no_poi"
                      :single-expand="false"
                      hide-default-footer
                      disable-pagination
                    >
                      <template v-slot:item.remove="{ item }">
                        <v-btn elevation="0" fab x-small>
                          <v-icon
                            class="d-flex justify-center align-center"
                            size="22"
                            color="primary"
                            @click="removeTimeSlot('weekday_no_poi', item)"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-10"></v-divider>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-row>
                      <v-col>
                        <h3>Add a weekday time period to disable POI announcements</h3>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                      <v-col cols="4">
                        <v-dialog ref="dialog" v-model="newWeekdayNoPoiStart" persistent width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="newWeekdayNoPoi.start_time"
                              label="Start"
                              :prepend-icon="icons.mdiClockTimeFourOutline"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="newWeekdayNoPoiStart"
                            v-model="newWeekdayNoPoi.start_time"
                            color="primary"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                newWeekdayNoPoi.start_time = ''
                                newWeekdayNoPoiStart = false
                              "
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                validateTimeRange('newWeekdayNoPoi')
                                newWeekdayNoPoiStart = false
                              "
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="2"> To </v-col>
                      <v-col cols="4">
                        <v-dialog ref="dialog" v-model="newWeekdayNoPoiEnd" persistent width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="newWeekdayNoPoi.end_time"
                              label="End"
                              :prepend-icon="icons.mdiClockTimeFourOutline"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="newWeekdayNoPoiEnd"
                            v-model="newWeekdayNoPoi.end_time"
                            color="primary"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                newWeekdayNoPoi.end_time = ''
                                newWeekdayNoPoiEnd = false
                              "
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                validateTimeRange('newWeekdayNoPoi')
                                newWeekdayNoPoiEnd = false
                              "
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="1" class="d-flex justify-center align-center">
                        <v-btn
                          class="mb-8"
                          fab
                          dark
                          x-small
                          color="success"
                          :disabled="!newWeekdayNoPoi.start_time || !newWeekdayNoPoi.end_time || !!newWeekdayNoPoiError"
                          @click="saveNewTimeSlot('weekday_no_poi', 'newWeekdayNoPoi')"
                        >
                          <v-icon class="d-flex justify-center align-center" size="18">
                            {{ icons.mdiCheck }}
                          </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="1" class="d-flex justify-center align-center">
                        <v-btn
                          class="mb-8"
                          fab
                          dark
                          x-small
                          color="primary"
                          @click="newWeekdayNoPoi = { ...VolumePeriodModel }"
                        >
                          <v-icon class="d-flex justify-center align-center" size="28">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-col v-if="newWeekdayNoPoiError">
                      <span class="font-weight-semibold error--text">{{ newWeekdayNoPoiError }}</span>
                    </v-col>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mb-4">
              <v-card-text>
                <!-- Weekend no POI announcement times -->
                <v-row>
                  <v-col>
                    <h2 class="text-left text-xl font-weight-semibold label">
                      Disable Point of Interest Announcements - Weekends
                    </h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-data-table
                      :headers="timePeriodHeaders"
                      :items="localSettings.weekend_no_poi"
                      :single-expand="false"
                      hide-default-footer
                      disable-pagination
                    >
                      <template v-slot:item.remove="{ item }">
                        <v-btn elevation="0" fab x-small>
                          <v-icon
                            class="d-flex justify-center align-center"
                            size="22"
                            color="primary"
                            @click="removeTimeSlot('weekend_no_poi', item)"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-10"></v-divider>
                <v-row>
                  <v-col offset="1" cols="9">
                    <v-row>
                      <v-col>
                        <h3>Add a weekend time period to disable transfer announcements</h3>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                      <v-col cols="4">
                        <v-dialog ref="dialog" v-model="newWeekendNoPoiStart" persistent width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="newWeekendNoPoi.start_time"
                              label="Start"
                              :prepend-icon="icons.mdiClockTimeFourOutline"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="newWeekendNoPoiStart"
                            v-model="newWeekendNoPoi.start_time"
                            color="primary"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                newWeekendNoPoi.start_time = ''
                                newWeekendNoPoiStart = false
                              "
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                validateTimeRange('newWeekendNoPoi')
                                newWeekendNoPoiStart = false
                              "
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="2"> To </v-col>
                      <v-col cols="4">
                        <v-dialog ref="dialog" v-model="newWeekendNoPoiEnd" persistent width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="newWeekendNoPoi.end_time"
                              label="End"
                              :prepend-icon="icons.mdiClockTimeFourOutline"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="newWeekendNoPoiEnd"
                            v-model="newWeekendNoPoi.end_time"
                            color="primary"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                newWeekendNoPoi.end_time = ''
                                newWeekendNoPoiEnd = false
                              "
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                validateTimeRange('newWeekendNoPoi')
                                newWeekendNoPoiEnd = false
                              "
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="1" class="d-flex justify-center align-center">
                        <v-btn
                          class="mb-8"
                          fab
                          dark
                          x-small
                          color="success"
                          :disabled="!newWeekendNoPoi.start_time || !newWeekendNoPoi.end_time || !!newWeekendNoPoiError"
                          @click="saveNewTimeSlot('weekend_no_poi', 'newWeekendNoPoi')"
                        >
                          <v-icon class="d-flex justify-center align-center" size="18">
                            {{ icons.mdiCheck }}
                          </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="1" class="d-flex justify-center align-center">
                        <v-btn
                          class="mb-8"
                          fab
                          dark
                          x-small
                          color="primary"
                          @click="newWeekendNoPoi = { ...VolumePeriodModel }"
                        >
                          <v-icon class="d-flex justify-center align-center" size="28">
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-col v-if="newWeekendNoPoiError">
                      <span class="font-weight-semibold error--text">{{ newWeekendNoPoiError }}</span>
                    </v-col>
                  </v-col>
                </v-row>
                <!-- end of times -->
              </v-card-text>
            </v-card>
          </div>
        </v-tab-item>

        <!-- Global Pronunciations -->
        <v-tab-item>
          <div v-if="is_loading" class="inline-loader">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
          <pronunciation-list v-else />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import {
  mdiCog,
  mdiTextToSpeech,
  mdiVolumeHigh,
  mdiPlayCircle,
  mdiVolumeOff,
  mdiClockOutline,
  mdiCalendarRemoveOutline,
  mdiCloseCircleOutline,
  mdiCheck,
  mdiBookOpenVariant,
  mdiSpeakerWireless,
  mdiMenuUp,
  mdiMenuDown,
} from "@mdi/js"
import { ref } from "@vue/composition-api"
import { mapActions, mapState } from "vuex"
import SaveButton from "../components/SaveButton.vue"
import PronunciationList from "./components/PronunciationList.vue"
import TooltipForTextToSpeechField from "../components/TooltipForTextToSpeechField.vue"

import store from "@/store"

export default {
  name: "Setting",
  metaInfo: {
    title: "Settings",
  },
  components: { SaveButton, TooltipForTextToSpeechField, PronunciationList },
  data() {
    return {
      currentTab: "",
      timePeriodHeaders: [
        { text: "From", value: "start_time", sortable: false },
        { text: "To", value: "end_time", sortable: false },
        { text: "Remove", value: "remove", sortable: false },
      ],
      newWeekdayPeakStart: false,
      newWeekdayPeakEnd: false,
      newWeekendPeakStart: false,
      newWeekendPeakEnd: false,
      newWeekdayQuietStart: false,
      newWeekdayQuietEnd: false,
      newWeekendQuietStart: false,
      newWeekendQuietEnd: false,
      newWeekdayPeakError: false,
      newWeekendPeakError: false,
      newWeekdayQuietError: false,
      newWeekendQuietError: false,
      newWeekdayNoTransferStart: false,
      newWeekdayNoTransferEnd: false,
      newWeekendNoTransferStart: false,
      newWeekendNoTransferEnd: false,
      newWeekdayNoTransferError: false,
      newWeekendNoTransferError: false,
      newWeekdayNoPoiStart: false,
      newWeekdayNoPoiEnd: false,
      newWeekendNoPoiStart: false,
      newWeekendNoPoiEnd: false,
      newWeekdayNoPoiError: false,
      newWeekendNoPoiError: false,
      tabs: [
        { title: "TTS segments", icon: mdiTextToSpeech },
        { title: "Global volume", icon: mdiVolumeHigh },
        { title: "Announcement settings ", icon: mdiSpeakerWireless },
        { title: "Global pronunciations", icon: mdiBookOpenVariant },
      ],
      localSettings: {},
      TTS: null,
      panel: [1],
      VolumePeriodModel: {
        start_time: "",
        end_time: "",
      },
      nextStopDefault: {
        intro_label: "Next stop",
        intro_tts: "Next stop",
      },
      newWeekdayPeak: {},
      newWeekendPeak: {},
      newWeekdayQuiet: {},
      newWeekendQuiet: {},
      newWeekdayNoTransfer: {},
      newWeekendNoTransfer: {},
      newWeekdayNoPoi: {},
      newWeekendNoPoi: {},
      nextStopOptions: [],
      is_loading: true,
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings,
    }),
  },
  watch: {
    settings: {
      handler(newSettings) {
        // Once populated settings come through via state, assign them to localSettings
        if (newSettings && Object.keys(newSettings).length > 0) {
          this.localSettings = JSON.parse(JSON.stringify(newSettings))

          // if there are no options, add the default one
          if (!this.localSettings.next_stop_options || !this.localSettings.next_stop_options.length) {
            Object.assign(this.nextStopOptions, [this.nextStopDefault])
          } else {
            Object.assign(this.nextStopOptions, this.localSettings.next_stop_options)
          }

          this.is_loading = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["TTSpreview"]),

    formatAndValidateSettings() {
      return new Promise((resolve, reject) => {
        // Check for negative numbers
        if (this.localSettings.max_display_duration < 0 || this.localSettings.min_display_duration < 0) {
          const errorMessage =
            "The Minimum and Maximum length to display stop details during announcement values cannot be negative numbers."
          store.commit("SET_SNACKBAR", {
            text: errorMessage,
            color: "error",
          })
          reject(errorMessage)

          return
        }

        // Check if min_display_duration exceeds max_display_duration
        if (this.localSettings.min_display_duration > this.localSettings.max_display_duration) {
          const errorMessage =
            "The 'Minimum length to display stop details during announcement' value exceeds the 'Maximum length to display stop details during announcement'."
          store.commit("SET_SNACKBAR", {
            text: errorMessage,
            color: "error",
          })
          reject(errorMessage)

          return
        }

        this.localSettings.weekday_peak = this.localSettings.weekday_peak?.filter(
          time => time.start_time && time.end_time,
        )
        this.localSettings.weekend_peak = this.localSettings.weekend_peak?.filter(
          time => time.start_time && time.end_time,
        )

        if (this.localSettings.offpeak_volume > 100) {
          this.localSettings.offpeak_volume = 100
        } else if (this.localSettings.offpeak_volume < 0) {
          this.localSettings.offpeak_volume = 0
        }

        if (this.localSettings.peak_volume > 100) {
          this.localSettings.peak_volume = 100
        } else if (this.localSettings.peak_volume < 0) {
          this.localSettings.peak_volume = 0
        }

        if (this.localSettings.quiet_volume > 100) {
          this.localSettings.quiet_volume = 100
        } else if (this.localSettings.quiet_volume < 0) {
          this.localSettings.quiet_volume = 0
        }

        if (this.localSettings.max_display_duration < 0) {
          this.localSettings.max_display_duration = 0
        }

        if (this.localSettings.min_display_duration < 0) {
          this.localSettings.min_display_duration = 0
        }

        this.localSettings.next_stop_options = this.nextStopOptions.reduce((acc, option) => {
          // if both intro_label and intro_tts are empty, skip this option
          if (!option.intro_label && !option.intro_tts) {
            return acc
          }

          // fallback to intro_label if intro_tts is empty and vice versa
          if (option.intro_label && !option.intro_tts) {
            option.intro_tts = option.intro_label
          } else if (!option.intro_label && option.intro_tts) {
            option.intro_label = option.intro_tts
          }
          acc.push(option)

          return acc
        }, [])

        resolve()
      })
    },
    async saveSettings() {
      await this.formatAndValidateSettings()
      store.dispatch("saveNextStopSettings", this.localSettings)
    },
    saveNewTimeSlot(settingType, newTimeSlot) {
      if (
        !this[newTimeSlot].start_time ||
        !this[newTimeSlot].end_time ||
        this[newTimeSlot].start_time >= this[newTimeSlot].end_time
      ) {
        return
      }

      this.localSettings[settingType] = [...(this.localSettings[settingType] || []), this[newTimeSlot]]

      // Reset the newTimeSlot object for future use - eg. newWeekdayPeak = {}
      this[newTimeSlot] = {}

      store.commit("SET_SNACKBAR", {
        text: `The new time slot has been added, save to apply`,
        color: "secondary",
      })
    },
    removeTimeSlot(settingType, slot) {
      const index = this.localSettings[settingType].indexOf(
        this.localSettings[settingType].find(p => p.start_time === slot.start_time && p.end_time === slot.end_time),
      )

      if (index !== -1) {
        const filteredArray = [
          ...this.localSettings[settingType].slice(0, index),
          ...this.localSettings[settingType].slice(index + 1),
        ]
        this.localSettings = {
          ...this.localSettings,
          [settingType]: filteredArray,
        }

        store.commit("SET_SNACKBAR", {
          text: `The time slot has been removed, save to apply`,
          color: "secondary",
        })
      }
    },
    validateTimeRange(timeCategory) {
      const start = this[`${timeCategory}`].start_time
      const end = this[`${timeCategory}`].end_time
      const isValid = !start || !end || start < end
      this[`${timeCategory}Error`] = isValid ? null : "Start must be before end"
    },
    addNextStopOption() {
      if (
        this.nextStopOptions[this.nextStopOptions.length - 1].intro_label === "" ||
        this.nextStopOptions[this.nextStopOptions.length - 1].intro_tts === ""
      ) {
        store.commit("SET_SNACKBAR", { text: "Please fill in the previous option", color: "warning" })

        return
      }
      this.nextStopOptions.push({
        intro_label: "",
        intro_tts: "",
      })
    },
    deleteNextStopOption(index) {
      this.nextStopOptions.splice(index, 1)
    },
    moveOptiondown(index) {
      if (index < this.nextStopOptions.length - 1) {
        const tempArray = [...this.nextStopOptions]
        const temp = this.nextStopOptions[index]
        tempArray[index] = this.nextStopOptions[index + 1]
        tempArray[index + 1] = temp
        this.nextStopOptions = tempArray
      }
    },
    moveOptionUp(index) {
      if (index > 0) {
        const tempArray = [...this.nextStopOptions]
        const temp = this.nextStopOptions[index]
        tempArray[index] = this.nextStopOptions[index - 1]
        tempArray[index - 1] = temp
        this.nextStopOptions = tempArray
      }
    },
  },
  setup() {
    const currentTab = ref(null)

    return {
      currentTab,
      icons: {
        mdiVolumeOff,
        mdiClockOutline,
        mdiCalendarRemoveOutline,
        mdiTextToSpeech,
        mdiVolumeHigh,
        mdiCog,
        mdiPlayCircle,
        mdiCloseCircleOutline,
        mdiCheck,
        mdiSpeakerWireless,
        mdiMenuUp,
        mdiMenuDown,
      },
    }
  },
}
</script>
